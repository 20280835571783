var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'كشف حساب منتج.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المنتج","plaseholder":"اختر منتج","show":'name',"refrance":'id',"value":_vm.params.product_id,"values":this.$database.products.concat(this.$database.materials)},model:{value:(_vm.params.product_id),callback:function ($$v) {_vm.$set(_vm.params, "product_id", $$v)},expression:"params.product_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المخزن","plaseholder":"اختر مخزن","show":'name',"refrance":'id',"value":_vm.params.store_id,"values":_vm.$database.stores},model:{value:(_vm.params.store_id),callback:function ($$v) {_vm.$set(_vm.params, "store_id", $$v)},expression:"params.store_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"كشف حساب منتج","details":[
            {title: 'اسم المنتج', value : _vm.productName },
            {title: 'اسم المخزن', value : _vm.storeName }
          ]}}),_c('reportTable',{attrs:{"header":[
            { colspan: 6, value: 'الرصيد الأفتتاحي' },
            {
              colspan: 1,
              value: _vm.startBalance,
            },
          ],"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            { column: 'code', title: 'الكود', type: 'reportCode' },
            { column: 'date', title: 'التاريخ', type: 'text' },
            { column: 'type', title: 'النوع', type: 'reportType' },
            { column: 'quantity', title: 'الكمية', type: 'quantity' },

            {
              column: 'debit',
              title: 'مدين',
              type: 'textOrEmpty',
            },
            {
              column: 'creditor',
              title: 'دائن',
              type: 'textOrEmpty',
            },
            {
              column: 'new_balance',
              title: 'الرصيد',
              type: 'text',
            },
          ],"footer":[
            { colspan: 6, value: 'الرصيد النهائي' },
            {
              colspan: 1,
              value: _vm.endBalance,
            },
          ]}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])
}]

export { render, staticRenderFns }