var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'السحب حسب المنتج.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المخزن","plaseholder":"اختر مخزن","show":'name',"refrance":'id',"value":_vm.params.store_id,"values":_vm.$database.stores},model:{value:(_vm.params.store_id),callback:function ($$v) {_vm.$set(_vm.params, "store_id", $$v)},expression:"params.store_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"المسحوبات حسب المنتج","details":[
            {title: 'اسم المستخدم', value : _vm.userName },
            {title: 'المخزن', value : _vm.storeName },
            {title: 'المنتج', value : _vm.productName },
            {title: 'التصنيف', value : _vm.sectionName },
          ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            { column: 'store_id',    title: _vm.$t('storesDeposits.storeName'), type: 'link', to:'store', sort: true, link: true},
            { column: 'product_name', link: '/products', title:'اسم المنتج' ,type: 'reportMainLink' , sort: true },
            { column: 'quantity', title: 'الكمية المسحوبه', type: 'text', sort: true },
          ],"footer":[
            { colspan: 2, value: 'المجموع' },
            { colspan: 1, value: _vm.quantity },
          ]}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])
}]

export { render, staticRenderFns }