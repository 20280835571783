var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.sales'),"description":_vm.$t('mainReport.Fromhereyoucancontrolyoursalesreports')}})],1),_c('div',{staticClass:"col-md-6"},[(
            (
              _vm.$user.role.available_reports.find(
                (el) => el.id == 1 || el.id == 2 || el.id == 3 || el.id == 4
              ) || _vm.$user.admin
            ) 
            && _vm.$site.customers_allow
          )?_c('reportsCard',{staticClass:"title-form",attrs:{"cardTitle":_vm.$t('mainReport.customers'),"icon":'fa-user-alt',"reports":[
            {
              name: _vm.$t('mainReport.TotalCustomerAccount'),
              link: '/customersTotalAccounts',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 1
                ) || _vm.$user.admin,
            },
            {
              name: _vm.$t('mainReport.CustomerAccountDetails'),
              link: '/customersDetailsAccounts',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 2
                ) || _vm.$user.admin,
            },
            {
              name: _vm.$t('mainReport.CustomerAssets'),
              link: '/customersBalance',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 3
                ) || _vm.$user.admin,
            },
            {
              name: _vm.$t('mainReport.CustomerGuide'),
              link: '/customersGuide',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 4
                ) || _vm.$user.admin,
            },

            {
              name: _vm.$t('mainReport.inactiveClients'),
              link: '/customersInactive',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 4
                ) || _vm.$user.admin,
            },
            {
              name: _vm.$t('mainReport.activeCustomers'),
              link: '/customersActive',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 4
                ) || _vm.$user.admin,
            },
            {
              name: _vm.$t('mainReport.customerTraffic'),
              link: '/customerTraffic',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 4
                ) || _vm.$user.admin,
            },
            {
              name: _vm.$t('mainReport.salesCollection'),
              link: '/salesCollection',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 4
                ) || _vm.$user.admin,
            },
          ]}}):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[(
          (
            _vm.$user.role.available_reports.find(
              (el) => el.id == 13 || el.id == 14
            ) || _vm.$user.admin
          )&& _vm.$site.invoices_allow 
          )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('mainReport.Sales'),"icon":'fa-file-invoice-dollar',"reports":[
            {
              name: _vm.$t('mainReport.SalesByInvoice'),
              link: './salesByInvoice',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 13
                ) || _vm.$user.admin,
            },
            {
              name: _vm.$t('mainReport.SalesByProduct'),
              link: '/salesByItem',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 14
                ) || _vm.$user.admin,
            },
            {
              name: _vm.$t('mainReport.customerSalesPrices'),
              link: '/customerSalesPrices',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 14
                ) || _vm.$user.admin,
            },
            {
              name: 'المبيعات حسب العميل',
              link: '/salesByCustomer',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 14
                ) || _vm.$user.admin,
            },
          ]}}):_vm._e(),(
          (
            _vm.$user.role.available_reports.find(
              (el) => el.id == 21 || el.id == 22
            ) || _vm.$user.admin
          )&&_vm.$site.orders_allow
          )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('mainReport.SalesOrders'),"icon":'fa-briefcase',"reports":[
            {
              name: _vm.$t('mainReport.OrdersByInvoice'),
              link: './salesOrdersByInvoice',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 21
                ) || _vm.$user.admin,
            },
            {
              name: _vm.$t('mainReport.OrdersByProduct'),
              link: '/salesOrdersByItem',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 22
                ) || _vm.$user.admin,
            },
          ]}}):_vm._e(),(
          (
            _vm.$user.role.available_reports.find(
              (el) => el.id == 17 || el.id == 18
            ) || _vm.$user.admin
          )&& _vm.$site.invoices_allow 
          )?_c('reportsCard',{attrs:{"cardTitle":_vm.$t('mainReport.invoices'),"icon":'fa-exchange',"reports":[
            {
              name: _vm.$t('mainReport.ReturnedByInvoice'),
              link: './salesReturnsByInvoice',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 17
                ) || _vm.$user.admin,
            },
            {
              name: _vm.$t('mainReport.ReturnedByProduct'),
              link: '/salesReturnsByItem',
              role:
                _vm.$user.role.available_reports.find(
                  (el) => el.id == 18
                ) || _vm.$user.admin,
            },
          ]}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }